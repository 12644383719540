import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { IClientDetails } from "../models/Clients/Interfaces/IClientDetails";
import ClientsService from "../services/ClientsService";
import update from "immutability-helper";
import { getServerSideErrors } from "../utils/utils";

export namespace ClientDetailsStore {
  export interface IState {
    loading: boolean;
    client: IClientDetails;
    hasServerSideErrors: boolean;
    errors: string;
  }

  export enum Actions {
    ToggleLoading = "CLIENT_DETAILS_TOGGLE_LOADING",
    SetClientDetails = "CLIENT_DETAILS_SET_CLIENT_DETAILS",
    SetErrors = "CLIENT_DETAILS_SET_ERRORS",
    ChangeClientState = "CLIENT_DETAILS_CHANGE_CLIENT_STATE",
  }

  interface IToggleLoading {
    type: Actions.ToggleLoading;
    loading: boolean;
  }

  interface ISetClientDetails {
    type: Actions.SetClientDetails;
    client: IClientDetails;
  }

  interface ISetErrors {
    type: Actions.SetErrors;
    hasServerSideErrors: boolean;
    errors: string;
  }

  interface IChangeClientState {
    type: Actions.ChangeClientState;
    deactivated: boolean;
  }

  type KnownAction =
    | IToggleLoading
    | ISetClientDetails
    | ISetErrors
    | IChangeClientState;

  export const actionCreators = {
    setClientDetails:
      (id: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        dispatch({ type: Actions.ToggleLoading, loading: true });

        var clientDetails: IClientDetails =
          await ClientsService.getClientDetails(id).then(
            (response) => response.value
          );

        dispatch({ type: Actions.SetClientDetails, client: clientDetails });

        dispatch({ type: Actions.ToggleLoading, loading: false });
      },
    deactivateClient:
      (clientId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var serverResponse = await ClientsService.deactivateClient(
          clientId
        ).then((response) => response);

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });

        if (!hasServerSideErrors) {
          dispatch({ type: Actions.ChangeClientState, deactivated: true });
        }
      },
    activateClient:
      (clientId: number): AppThunkAction<KnownAction> =>
      async (dispatch, getState) => {
        var serverResponse = await ClientsService.activateClient(clientId).then(
          (response) => response
        );

        var errors = getServerSideErrors(serverResponse);
        var hasServerSideErrors = errors ? true : false;

        dispatch({
          type: Actions.SetErrors,
          hasServerSideErrors: hasServerSideErrors,
          errors: errors,
        });

        if (!hasServerSideErrors) {
          dispatch({ type: Actions.ChangeClientState, deactivated: false });
        }
      },
  };

  const initialClientDetails: IClientDetails = {
    id: null,
    name: "",
    createdAt: "",
    deactivated: false,
    countryId: 0,
    countryName: "",
    currency: "",
    legalClientName: "",
    industry: "",
    noEmployees: null,
    details: "",
    subscriptionId: null,
    subscriptionPrice: null,
    subscriptionCurrency: "",
    subscriptionStartDate: "",
    renewalIntervalValue: null,
    renewalIntervalUnit: null,
    lastRenewalDate: "",
    nextRenewalDate: "",
    accountManagerEmail: "",
    contactPhone: "",
    contactEmail: "",
    emailDomains: [],
    salesPersonEmail: "",
    cui: "",
    logo: "",
    altLogo: "",
    subscriptionMetadata: {
      vat: null,
      platformAccessFee: null,
      platformAccessFeeCurrency: null,
      prepaid: null,
      prepaidEntries: null,
      minimumSpend: null
    },
  };

  const initialState: IState = {
    loading: false,
    client: initialClientDetails,
    hasServerSideErrors: false,
    errors: "",
  };

  export const reducer: Reducer<IState> = (
    currentState: IState,
    incomingAction: Action
  ) => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
      case Actions.ToggleLoading:
        return { ...currentState, loading: action.loading };
      case Actions.SetClientDetails:
        return update(currentState, {
          client: { $set: action.client },
        });
      case Actions.SetErrors:
        return update(currentState, {
          hasServerSideErrors: { $set: action.hasServerSideErrors },
          errors: { $set: action.errors },
        });
      case Actions.ChangeClientState:
        return {
          ...currentState,
          client: {
            ...currentState.client,
            deactivated: action.deactivated,
          },
        };
      default:
        return currentState || initialState;
    }
  };
}
