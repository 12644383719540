import Globals from "./../../../Globals";

export default class AddOrEditClient {
  public accountOwnerInfoDesc: string = `Complete the email address & phone number for the customer's account owner`;
  public accountOwnerInfoLabel: string = `Account owner information`;
  public addCustomerText: string = `Add customer`;
  public addEmailDomainText: string = `Add domain`;
  public contactInfoDesc: string = `Complete the email address & phone number for the customer's contact person`;
  public contactInfoLabel: string = `Contact email address & phone number`;
  public countryLabel: string = `Country`;
  public cuiLabel: string = `CUI/Company number`;
  public currencyLabel: string = `Currency`;
  public customerNameText: string = `Customer name`;
  public editCustomerHeader: string = `Edit customer`;
  public emailAddressPlaceholder: string = `Email address`;
  public emailDomainsDesc: string = `Based on domains, employees will be able to register in mobile application. Click on "Add domain" button, enter the domain without "@" and press enter`;
  public emailDomainsLabel: string = `Email domains`;
  public generalInformationSectionLabel: string = `General information`;
  public industryLabel: string = `Industry`;
  public internalAccountOwnerSectionLabel: string = `Internal account owner`;
  public legalCustomerNameLabel: string = `Legal customer name`;
  public metadataSectionLabel: string = `More info (metadata)`;
  public noEmployeesLabel: string = `Number of employees`;
  public notesDesc: string = `These comments are only for internal purposes and will not be displayed anywhere`;
  public notesLabel: string = `Notes/comments`;
  public paymentRenewal: string = `Payment renewal`;
  public paymentRenewalTypeText: string = `Payment renewal type`;
  public paymentRenewalValueText: string = `Payment renewal value`;
  public permissionsForBookingSectionLabel: string = `Permissions for booking`;
  public permissionsForVisibilitySectionLabel: string = `Permissions for visibility`;
  public phoneNumberPlaceholder: string = `Phone number`;
  public pluriaAccountManagerDesc: string = `Complete the email address for the Pluria member that manages communication with this customer`;
  public plurialAccountManagerLabel: string = `Pluria account manager`;
  public pluriaSalesPersonDesc: string = `Complete the email for the Pluria sales person`;
  public pluriaSalesPersonLabel: string = `Pluria sales person`;
  public pricingPlanDetailsSectionLabel: string = `Pricing plan Details`;
  public pricingPlanLabel: string = `Pricing Plan Name`;
  public pricingPlanMininumSpend: string = `Minimum spend`;
  public pricingPlanAccessFee: string = `Platform access fee`;
  public pricingPlanAccessFeeCurrency: string = `Platform access fee currency`;
  public pricingPlanPrepaid: string = `Monthly prepaid`;
  public pricingPlanPrepaidEntries: string = `Prepaid entries`;
  public pricingPlanVAT: string = `VAT/TVA`;
  public pricingPlanStartDateLabel: string = `Start date`;
  public saveChangesOnEditText: string = `Save changes`;
  public selectAnIndustryPlaceholder: string = `Select an industry`;
  public selectCountryPlaceholder: string = `Select country`;
  public selectCurrencyPlaceholder: string = `Select currency`;
  public selectRenewalTypePlaceholder: string = `Select renewal type`;
  public typeOfPlanDesc: string = `Select the type of plan you want to create for the new customer`;
  public typeOfPlanLabel: string = `Type of plan`;
  public altLogo: string = `Alternative logo (smaller)`;
  public logo: string = `Logo`;
  public acceptedFormatAltLogo: string = `Accepted format: .png, .jpg, .jpeg`;
  public acceptedFormatLogo: string = `Accepted format: .png, .jpg, .jpeg`;
  public automatedEmails: string = `AUTOMATED EMAILS`;
  public emailLanguage: string = `Email language`;
  public chooseInWhichLanguageTheEmailsShouldBeSent: string = `Choose in which language the emails should be sent for this customer`;

  public static get Resources(): AddOrEditClient {
    switch (Globals.currentActiveLanguage) {
      default:
        return new AddOrEditClient();
    }
  }
}
