import Globals from "./../../../Globals";

export default class Subscriptions {
       public addPricingPlanDescText: string = `Complete the information below to create a pricing plan for this customer.`;
       public addPricingPlanText: string = `Add pricing plan`;
       public currencyNameText: string = `Currency`;
       public customer: string = `Customer`;
       public deactivateContentText: string = `Are you sure you want to deactivate this pricing plan? All the associated services will be deactivated, without the possibility to reactivate them. You will need to add a new pricing plan and services.`;
       public deactivatePricingPlanButtonText: string = `Deactivate pricing plan`;
       public editPricingPlanText: string = `Edit pricing plan`;
       public id: string = `Id`;
       public lastRenewedOn: string = `Last on {0}`;
       public nextRenewedOn: string = `Next on {0}`;
       public noPricingPlanYetText: string = `This customer does not have an active pricing plan set yet`;
       public paymentRenewalTableHeader: string = `Payment renewal type & value`;
       public paymentRenewalTypeText: string = `Payment renewal type`;
       public paymentRenewalValueText: string = `Payment renewal value`;
       public pricingPlanNameTableHeader: string = `NAME`;
       public pricingPlanMininumSpend: string = `Minimum spend`;
       public pricingPlanMonthlyPrepaid: string = `Monthly prepaid`;
       public pricingPlanLabel: string = `Pricing Plan`;
       public pricingPlanAccessFee: string = `Platform access fee`;
       public pricingPlanAccessFeeCurrency: string = `Platform access fee currency`;
       public pricingPlanPrepaidEntries: string = `Prepaid entries`;
       public pricingPlanVAT: string = `VAT/TVA`;
       public pricingPlanStartDateTableHeader: string = `Start date`;
       public pricingPlanStartDateText: string = `Start date`;
       public pricingPlanText: string = `Pricing plan`;
       public pricingPlanTooltipText: string = `There can only be one pricing plan associated to the customer. If you want to add a new pricing plan, you have to deactivate the existing one.`;
       public renewDatesTableHeader: string = `Renew dates`;
       public selectCurrencyText: string = `Select currency`;
       public selectRenewalTypeText: string = `Select renewal type`;
       public selectStartDateText: string = `Select start date`;

		public static get Resources(): Subscriptions{
			switch (Globals.currentActiveLanguage) {
				default:
				return new Subscriptions();
		}
   	}
}
